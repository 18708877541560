












































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky/index.vue'
import {
  locales,
  convertToMapTranslations,
  convertToArrayTranslations,
  errorMsg,
  successMsg,
  confirmDialog,
  validateForm,
  initSocket,
  hasRoles,
  hasOfficeService
} from '@/utils'
import {
  approvePropertyChanges,
  deleteProperty,
  getProperty,
  rejectPropertyChanges,
  saveProperty,
  savePropertyWithMedia,
  sentPropertyForApproval,
  sentToService
} from '@/api/properties'
import { PropertyTranslation } from '@/models/PropertyTranslation'
import { Property } from '@/models/Property'
import Draggable from 'vuedraggable'
import { AppModule, DeviceType } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { actions, isLatitudeValid, isLongitudeValid } from '@/utils/property'
import { Form, Message } from 'element-ui'
import { ElMessageComponent } from 'element-ui/types/message'
import TechnicalFeatures from '@/views/property/components/TechnicalFeatures.vue'
import LandTechnicalFeatures from '@/views/property/components/LandTechnicalFeatures.vue'
import LocationInformation from '@/views/property/components/LocationInformation.vue'
import BasicInformation from '@/views/property/components/BasicInformation.vue'
import MoreAttributes from '@/views/property/components/MoreAttributes.vue'
import PropertyPosition from '@/views/property/components/PropertyPosition.vue'
import Construction from '@/views/property/components/Construction.vue'
import Heating from '@/views/property/components/Heating.vue'
import Comments from '@/views/property/components/Comments.vue'
import MediaAlert from '@/views/property/components/MediaAlert.vue'
import ImageGallery from '@/views/property/components/ImageGallery.vue'
import FileGallery from '@/views/property/components/FileGallery.vue'
let validationErrors: string[] = []
let message: ElMessageComponent
@Component({
  name: 'PropertyDetail',
  components: {
    PropertyDetailHeader: () => import('@/views/property/components/PropertyDetailHeader.vue'),
    PromotionalActions: () => import('@/views/property/components/PromotionalActions.vue'),
    PropertyManagement: () => import('@/views/property/components/PropertyManagement.vue'),
    PropertyHistory: () => import('@/views/property/components/PropertyHistory.vue'),
    VideoGallery: () => import('@/views/property/components/VideoGallery.vue'),
    BridgeEvents: () => import('@/views/property/components/BridgeEvents.vue'),
    PropertyTraffic: () => import('@/views/property/components/PropertyTraffic.vue'),
    Visits: () => import('@/views/property/components/Visits.vue'),
    Calls: () => import('@/views/property/components/Calls.vue'),
    LandTechnicalFeatures,
    draggable: Draggable,
    LocationInformation,
    TechnicalFeatures,
    BasicInformation,
    PropertyPosition,
    MoreAttributes,
    Construction,
    ImageGallery,
    FileGallery,
    MediaAlert,
    Comments,
    Heating,
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  @Prop({ default: false }) private isProfile!: boolean

  private property = new Property({
    user_id: UserModule.id,
    office_id: UserModule.officeId,
    meta: {
      show_on_spitogatos: hasOfficeService('spitogatos'),
      show_on_plot: hasOfficeService('plot'),
      show_on_xe: hasOfficeService('xe'),
      actions
    }
  })

  private translations = convertToMapTranslations([], PropertyTranslation)
  private skeletonLoader = false
  private saveLoader = false
  private languages = locales
  private language = locales[0]
  private images = []
  private images360 = []
  private files = []
  private activeName = 'attributes'

  private formValidator = (rule:any, value:any, callback:any) => {
    if (value == '' || value == undefined) {
      validationErrors.push(this.$t('propertyValidation.' + rule.field).toString())
      callback(new Error(this.$t('form.isRequired').toString()))
    } else {
      callback()
    }
  }

  private floorValidator = (rule:any, value:any, callback:any) => {
    const a = value || -3
    if (value === 0 || a >= -2) {
      callback()
    } else {
      validationErrors.push(this.$t('propertyValidation.' + rule.field).toString())
      callback(new Error(this.$t('form.isRequired').toString()))
    }
  }

  private validateYearValue = (rule: any, value: any, callback: Function) => {
    const currentYear = new Date().getFullYear()
    if (!Number.isInteger(value) || value < 1901 || value > currentYear) {
      const fieldName = this.$t('propertyDetail.' + this.toCamelCase(rule.field)).toString()
      validationErrors.push(this.$t('propertyValidation.yearValidation', { fieldName, currentYear }).toString())
      return callback(new Error(this.$t('formValidationErrors.yearValidation', { currentYear }).toString()))
    }
    callback()
  };

  private validateConstructionYear = (rule: any, value: any, callback: Function) => {
    this.validateYearValue(rule, value, callback)
  };

  private validateRenovationYear = (rule: any, value: any, callback: Function) => {
    if (!value) {
      return callback()
    }
    this.validateYearValue(rule, value, callback)
  };

  private validateLatitude = (rule: any, value: any, callback: Function) => {
    if (isLatitudeValid(value)) {
      callback()
    } else {
      validationErrors.push(this.$t('propertyValidation.latitude').toString())
      callback(this.$t('formValidationErrors.invalidLatitude'))
    }
  }

  private validateLongitude = (rule: any, value: any, callback: Function) => {
    if (isLongitudeValid(value)) {
      callback()
    } else {
      validationErrors.push(this.$t('propertyValidation.longitude').toString())
      callback(this.$t('formValidationErrors.invalidLongitude'))
    }
  }

  private needsToBeCleared = false
  private rules: any = {}
  private mainRules: any = {
    available_for: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    category: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    levels: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    assignation_state: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    type: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    floor: [{
      required: true,
      validator: this.floorValidator,
      trigger: 'change'
    }],
    state: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    construction_year: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }, {
      validator: this.validateConstructionYear,
      trigger: 'change'
    }],
    renovation_year: [{
      validator: this.validateRenovationYear,
      trigger: 'change'
    }],
    energy_class: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    code: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    price: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    acreage: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    client_id: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    area_id: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    auction_date: [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    'meta.e_auction_link': [{
      required: true,
      validator: this.formValidator,
      trigger: 'change'
    }],
    'location.coordinates[0]': [
      { validator: this.validateLatitude, trigger: 'blur' }
    ],
    'location.coordinates[1]': [
      { validator: this.validateLongitude, trigger: 'blur' }
    ]
  }

  created() {
    this.getItem(this.$route.params.id).then(() => {
      if (this.property.status === 'sent_for_approval') {
        this.needsToBeCleared = true
        this.checkDiffRules(this.$t('propertyValidation.field_changes').toString())
      } else {
        this.restoreRules()
      }
    })
  }

  async mounted() {
    await initSocket((wrapper: any) => {
      if (wrapper.message.data.event !== 'image_upload' || wrapper.message.data.property_id !== this.$route.params.id) {
        return
      }
      this.$nextTick(() => {
        try {
          if (wrapper.message.data.type === 'files') {
            this.files.forEach((file: any, index: number) => {
              if (wrapper.message.data.image === file.name) {
                file.isNew = false
                this.$set(this.files, index, file)
              }
            })
            return
          }
          const images: any = wrapper.message.data.type === 'images' ? this.images : this.images360

          const i = images.findIndex((image: any) => {
            if (!image.name) {
              return false
            }
            const noSpaceName = image.name.replaceAll(' ', '_')
            let isWebp = noSpaceName.toLowerCase().endsWith('webp');
            let isPdf = noSpaceName.toLowerCase().endsWith('pdf');
            if (isWebp || isPdf) {
              let index  = isWebp ?  noSpaceName.length - 5 : noSpaceName.length - 4;
              const newName = noSpaceName.substring(0, index) + '.png'
              image.name = newName
              image.type = 'image/png'
              return newName === wrapper.message.data.image
            } else {
              return noSpaceName === wrapper.message.data.image
            }
          })
          if (i > -1) {
            this.$set(images, i, this.transformImage(images[i], i))
          }
        } catch (e) {
          console.error(e)
        }
      })
    })
  }

  get isSentForApproval() {
    return this.property.status === 'sent_for_approval' || (
      this.property.status === 'in_process' &&
      hasRoles(['super_admin', 'broker'], UserModule.roles)
    )
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  get isBroker() {
    return hasRoles(['super_admin'], UserModule.roles)
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getProperty({ id })
      data.meta = data.meta || {}
      data.meta.diffs = data.meta.diffs || []
      this.property = data
      this.translations = convertToMapTranslations(data.translations, PropertyTranslation)
      this.images = JSON.parse(JSON.stringify(data.media.images))
      this.images360 = JSON.parse(JSON.stringify(data.media.images360))
      this.files = JSON.parse(JSON.stringify(data.media.files || '[]'))
    } catch (err) {}
    this.skeletonLoader = false
  }

  private async deleteItem() {
    const [valid] = await confirmDialog('propertyList.delete')
    if (!valid) return
    try {
      await deleteProperty({
        id: this.property.id
      })
      await successMsg('propertyList.deleteSuccess')
      return this.$router.push('/property/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private toCamelCase(str: string) {
    return str.split('_').map((word, index) => index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('')
  }

  private prepareImagesForSave(params: any = {}) {
    const images = params.images
    const transformedImages = []
    for (let i = 0; i < images.length; i++) {
      if (images[i].raw) {
        params.formData.append(params.key, images[i].raw)
        images[i].loading = true
        images[i].raw = null
      }
      transformedImages.push(this.transformImage(images[i], i))
    }
    return transformedImages
  }

  private transformImage(image: any, order: number) {
    return {
      name: image.name,
      type: image.type,
      description: image.description,
      watermark: image.watermark || false,
      order
    }
  }

  private prepareFilesForSave(params: any = {}) {
    const files = params.files
    const transformedImages = []
    for (let i = 0; i < files.length; i++) {
      if (files[i].raw) {
        params.formData.append(params.key, files[i].raw)
        files[i].loading = true
        files[i].raw = null
      }
      transformedImages.push(this.transformFile(files[i], i))
    }
    return transformedImages
  }

  private transformFile(image: any, order: number) {
    return {
      name: image.name,
      type: image.type,
      order
    }
  }

  private async sentItemForApproval() {
    const valid = await this.validatePropertyForm()
    if (!valid) return
    this.saveLoader = true
    try {
      this.beforeSaveProperty()
      await this.saveItem()
      this.property.status = 'in_process'
      await sentPropertyForApproval({
        id: this.property.id
      })
      this.property.status = 'sent_for_approval'
      await successMsg('propertyDetail.successSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.saveLoader = false
  }

  private async approveItemChanges() {
    const valid = await this.validatePropertyForm()
    if (!valid) return
    this.saveLoader = true
    try {
      this.beforeSaveProperty()
      await this.saveItem()
      this.property.status = 'in_process'
      await approvePropertyChanges({
        id: this.property.id
      })
      await successMsg('propertyDetail.successSave')
      this.property.status = 'approved'
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.saveLoader = false
  }

  private async rejectItemChanges() {
    this.saveLoader = true
    try {
      await rejectPropertyChanges({
        id: this.property.id
      })
      await successMsg('propertyDetail.successSave')
      this.property.status = 'changes_required'
      if (this.needsToBeCleared) {
        this.needsToBeCleared = false
        await this.checkDiffRules()
      }
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.saveLoader = false
  }

  private async sentItemToService(service: string) {
    try {
      this.saveLoader = true
      await sentToService({
        id: this.property.id,
        service
      })
      await successMsg('propertyDetail.successSave')
    } catch (err) {
      await errorMsg('api.serverError')
    } finally {
      this.saveLoader = false
    }
  }

  private onLanguageChange(language: string) {
    this.language = language
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'sent_for_approval': {
        this.sentItemForApproval()
        break
      }
      case 'approve_item': {
        this.approveItemChanges()
        break
      }
      case 'reject_item': {
        this.rejectItemChanges()
        break
      }
      case 'save_item': {
        this.submitForm()
        break
      }
      case 'delete_item': {
        this.deleteItem()
        break
      }
      case 'sent_xe':
      case 'sent_plot':
      case 'sent_spitogatos': {
        this.sentItemToService(command.split('_')[1])
        break
      }
    }
  }

  private async saveItem() {
    const formData = new FormData()
    this.property.area = null
    this.property.user = null
    this.property.client = null

    this.property.media.images = this.prepareImagesForSave({
      images: this.images,
      formData: formData,
      key: 'images'
    })

    this.property.media.images360 = this.prepareImagesForSave({
      images: this.images360,
      formData: formData,
      key: 'images360'
    })
    this.property.media.files = this.prepareFilesForSave({
      files: this.files,
      formData: formData,
      key: 'files'
    })

    if (formData.get('images') || formData.get('images360') || formData.get('files')) {
      formData.append('property', JSON.stringify(this.property))
      return savePropertyWithMedia(formData)
    }

    const result = await saveProperty(this.property)
    if (this.needsToBeCleared) {
      this.needsToBeCleared = false
      await this.checkDiffRules()
    }
    return result
  }

  private async checkDiffRules(message = '') {
    try {
      this.clearRules()
      const diffs = this.property.meta.diffs || []
      diffs.forEach((diff: string) => {
        const rule = [{
          validator: (rule:any, value:any, callback:any) => {
            if (message) {
              callback(new Error(message))
            } else {
              callback()
            }
          }
        }]
        if (diff.startsWith('translations_')) {
          const key = this.getKeyFromTranslations(diff)
          if (key) this.$set(this.rules, key, rule)
        } else if (diff === 'location') {
          this.$set(this.rules, 'latitude', rule)
          this.$set(this.rules, 'longitude', rule)
        } else {
          this.$set(this.rules, diff, rule)
        }
      })
      await validateForm(this.$refs.propertyForm as Form)
    } catch (e) {}
    this.restoreRules()
  }

  private getKeyFromTranslations(value: string) {
    const keys = ['portal', 'address', 'public_address', 'title', 'description']
    const idx = keys.findIndex((key) => value.endsWith(`_${key}`))
    if (idx !== -1) return keys[idx]
    return null
  }

  private clearRules() {
    Object.keys(this.rules).forEach((key) => {
      this.$delete(this.rules, key)
    })
  }

  private restoreRules() {
    this.clearRules()
    Object.keys(this.mainRules).forEach(key => {
      this.$set(this.rules, key, this.mainRules[key])
    })
  }

  private beforeSaveProperty() {
    this.property.translations = convertToArrayTranslations(
      this.translations,
      PropertyTranslation
    )
  }

  private async validatePropertyForm() {
    validationErrors = []
    const [valid] = await validateForm(this.$refs.propertyForm as Form)
    if (message) {
      message.close()
    }
    if (!valid) {
      if (validationErrors.length > 0) {
        message = Message.error({
          showClose: true,
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: validationErrors.join('<br/><br/>')
        })
      } else {
        errorMsg('form.formErrors')
      }
    }
    return valid
  }

  private async submitForm() {
    const valid = await this.validatePropertyForm()
    if (!valid) return
    this.saveLoader = true
    try {
      this.beforeSaveProperty()
      const { data } = await this.saveItem()
      if (!this.isEdit) {
        return this.$router.push('/property/edit/' + data.id)
      }
      this.property.status = 'in_process'
      await successMsg('propertyDetail.successSave')
    } catch (err) {
      if (err.response && err.response.status === 409) {
        await errorMsg('api.codeAlreadyExists')
      } else {
        await errorMsg('api.serverError')
      }
    }
    this.saveLoader = false
  }
}
