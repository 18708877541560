
































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { errorMsg } from '@/utils'
import { mediaTypes } from '@/utils/property'
import {
  setImageRotation,
  setWatermark
} from '@/api/properties'
@Component({
  name: 'ImageGallery',
  components: {
    Draggable
  }
})

export default class extends Vue {
  @Prop({ required: true }) private images!: any
  @Prop({ required: true }) private entityId!: any
  @Prop({ required: true }) private type!: string
  @Prop({ required: false }) private suffix!: string
  private mediaTypes = mediaTypes
  private description = ''

  private applyDescToAll() {
    this.images.forEach((image: any) => {
      image.description = this.description
    })
  }

  private getImage(image: any) {
    return `/static/${this.type}/${this.entityId}/images${this.suffix || ''}/${encodeURIComponent(image.name)}`
  }

  private createImageObject(event: any) {
    return {
      name: event.name.replaceAll(' ', '_'),
      src: event.raw.type==='application/pdf' ? '/static/public/pdf_icon.png':URL.createObjectURL(event.raw),
      raw: event.raw,
      watermark: true,
      types: ''
    }
  }

  private async watermark(image:any) {
    image.watermark = image.watermark || false
    await setWatermark({
      propertyId: this.entityId,
      image: image.name,
      type: `images${this.suffix || ''}`,
      watermark: !image.watermark
    })
    image.watermark = !image.watermark
    const imageTag:any = document.getElementById(image.name)
    const src = imageTag.src
    imageTag.src = ''
    imageTag.src = `${src}?rnd=${+new Date()}`
  }

  private async rotate(image:any, degrees:Number) {
    image.watermark = image.watermark || false
    await setImageRotation({
      propertyId: this.entityId,
      image: image.name,
      type: `images${this.suffix || ''}`,
      rotation: degrees
    })
    const imageTag:any = document.getElementById(image.name)
    const src = imageTag.src
    imageTag.src = ''
    imageTag.src = `${src}?rnd=${+new Date()}`
  }

  private imageExists(event: any) {
    const name = event.name && event.name.replaceAll(' ', '_')
    return this.images.some((image: any) => image.name === name)
  }

  private validateImageType(event: any) {
    console.log("type -> ",event.raw.type);
    return ['image/png', 'image/jpg', 'image/jpeg', 'image/webp','application/pdf'].includes(event.raw.type)
  }

  private handleOnChange(event: any) {
    if (!this.validateImageType(event)) {
      return errorMsg('propertyDetail.invalidImageType')
    }
    if (this.imageExists(event)) {
      return errorMsg('propertyDetail.imageExists')
    }
    this.images.push(this.createImageObject(event))
  }

  private imageSrc(item: any) {
    return item.src || this.getImage(item)
  }

  private removeFromGallery(index: number) {
    if (index >= 0) {
      this.images.splice(index, 1)
    }
  }
}
